import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { LocationDTO } from 'app/location/location.model';
import { map } from 'rxjs';
import { transformRecordToMap } from 'app/common/utils';


@Injectable({
  providedIn: 'root',
})
export class LocationService {

  http = inject(HttpClient);
  resourcePath = environment.apiPath + '/api/locations';

  getAllLocations() {
    return this.http.get<LocationDTO[]>(this.resourcePath);
  }

  getLocation(id: number) {
    return this.http.get<LocationDTO>(this.resourcePath + '/' + id);
  }

  createLocation(locationDTO: LocationDTO) {
    return this.http.post<number>(this.resourcePath, locationDTO);
  }

  updateLocation(id: number, locationDTO: LocationDTO) {
    return this.http.put<number>(this.resourcePath + '/' + id, locationDTO);
  }

  deleteLocation(id: number) {
    return this.http.delete(this.resourcePath + '/' + id);
  }

  getSellerValues() {
    return this.http.get<Record<string,number>>(this.resourcePath + '/sellerValues')
        .pipe(map(transformRecordToMap));
  }

}
