import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  
  /**
   * Constructs a full API URL by combining the environment's apiPath with the endpoint path
   * Handles empty apiPath values correctly
   * 
   * @param endpoint The API endpoint path (should start with '/')
   * @returns The full API URL
   */
  getApiUrl(endpoint: string): string {
    // Make sure endpoint starts with a slash
    if (!endpoint.startsWith('/')) {
      endpoint = '/' + endpoint;
    }
    
    // If apiPath is empty, just return the endpoint
    if (!environment.apiPath) {
      return endpoint;
    }
    
    // Remove trailing slash from apiPath if it exists
    const apiPath = environment.apiPath.endsWith('/') 
      ? environment.apiPath.slice(0, -1) 
      : environment.apiPath;
      
    return `${apiPath}${endpoint}`;
  }
} 