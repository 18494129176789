import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';

/**
 * Guard do ochrony edycji konta użytkownika.
 * Sprawdza, czy ID z URL jest tym samym ID co aktualnie zalogowany użytkownik.
 * Zapobiega to możliwości edycji kont innych użytkowników przez podmianę ID w URL.
 */
export const userEditGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  
  // Pobierz aktualnie zalogowanego użytkownika
  const currentUser = authService.getCurrentUser();
  
  // Pobierz ID z parametrów URL
  const requestedUserId = +route.params['id'];
  
  // Sprawdź, czy użytkownik jest zalogowany i czy edytuje własne konto
  if (authService.isAuthenticated() && currentUser && currentUser.id === requestedUserId) {
    return true;
  }
  
  // Jeśli użytkownik nie jest zalogowany, przekieruj do logowania
  if (!authService.isAuthenticated()) {
    router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
  
  // Jeśli użytkownik próbuje edytować konto innego użytkownika, przekieruj do własnego profilu
  router.navigate(['/user/about']);
  return false;
}; 