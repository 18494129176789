import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, tap, map } from 'rxjs';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { ApiUrlService } from '../common/api-url.service';

declare const FB: any;

export interface User {
  id: number;
  email: string;
  displayName: string;
  isSeller: boolean;
}

export interface AuthResponse {
  token: string;
  user: User;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private http = inject(HttpClient);
  private router = inject(Router);
  private apiUrlService = inject(ApiUrlService);
  private currentUserSubject = new BehaviorSubject<AuthResponse | null>(null);
  currentUser$ = this.currentUserSubject.asObservable();

  constructor() {
    const savedAuth = localStorage.getItem('auth');
    if (savedAuth) {
      this.currentUserSubject.next(JSON.parse(savedAuth));
    }
  }

  login(email: string, password: string) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/login');
    return this.http.post<AuthResponse>(apiUrl, { email, password })
      .pipe(
        tap(response => {
          localStorage.setItem('auth', JSON.stringify(response));
          this.currentUserSubject.next(response);
        })
      );
  }

  register(email: string, displayName: string, password: string, isSeller: boolean = false) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/register');
    return this.http.post<{success: boolean}>(apiUrl, { 
      email, 
      displayName, 
      password,
      isSeller
    }, {
      observe: 'response'  // Return the full HTTP response
    }).pipe(
      tap(response => {
        console.log('Registration response:', response);
      }),
      map(response => response.body as {success: boolean})
    );
  }

  verifyEmail(token: string) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/verify');
    return this.http.get<{success: boolean}>(apiUrl, { params: { token } });
  }

  /**
   * Ponownie wysyła email weryfikacyjny
   * @param email Adres email użytkownika
   */
  resendVerificationEmail(email: string) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/resend-verification');
    return this.http.post<{success: boolean, message: string}>(apiUrl, { email });
  }

  // Nowe metody do obsługi resetowania hasła
  
  /**
   * Wysyła żądanie resetowania hasła
   * @param email Adres email użytkownika
   */
  requestPasswordReset(email: string) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/forgot-password');
    return this.http.post<{success: boolean}>(apiUrl, { email });
  }
  
  /**
   * Waliduje token resetowania hasła
   * @param token Token resetowania hasła
   */
  validateResetToken(token: string) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/reset-password/validate');
    return this.http.get<{valid: boolean}>(apiUrl, { params: { token } });
  }
  
  /**
   * Resetuje hasło użytkownika
   * @param token Token resetowania hasła
   * @param newPassword Nowe hasło
   */
  resetPassword(token: string, newPassword: string) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/reset-password');
    return this.http.post<{success: boolean}>(apiUrl, { token, password: newPassword });
  }

  loginWithFacebook(accessToken: string, isSeller: boolean = false) {
    const apiUrl = this.apiUrlService.getApiUrl('/api/auth/facebook');
    return this.http.post<AuthResponse>(apiUrl, { 
      accessToken,
      isSeller
    })
      .pipe(
        tap(response => {
          localStorage.setItem('auth', JSON.stringify(response));
          this.currentUserSubject.next(response);
        })
      );
  }

  logout() {
    // Clean up Facebook session if FB SDK is available
    if (typeof FB !== 'undefined') {
      FB.getLoginStatus((response: any) => {
        if (response.status === 'connected') {
          FB.logout();
        }
      });
    }
    
    localStorage.removeItem('auth');
    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);
  }

  getToken() {
    return this.currentUserSubject.value?.token;
  }

  isAuthenticated() {
    return !!this.currentUserSubject.value;
  }

  getCurrentUser(): User | null {
    return this.currentUserSubject.value?.user || null;
  }
} 