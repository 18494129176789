<div class="login-container">
  <div class="login-overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5 col-lg-4">
          <div class="login-card compact-width">
            <!-- Logo na górze karty logowania -->
            <div class="login-logo-container text-center">
              <img src="logo_tx_flat.png" alt="Stragan Logo" class="login-logo"
                   onerror="this.onerror=null; this.src='assets/logo_tx_flat.png';">
            </div>

            <!-- Tytuł -->
            <div class="login-header text-center compact">
              <p class="text-muted welcome-text">
                Resetowanie hasła
              </p>
            </div>

            <div class="login-body">
              @if (!successMessage) {
                <p class="text-muted mb-4">
                  Podaj adres email powiązany z Twoim kontem. Wyślemy Ci link do zresetowania hasła.
                </p>

                @if (errorMessage) {
                  <div class="alert alert-danger mb-3">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>
                    <span>{{ errorMessage }}</span>
                  </div>
                }

                <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="email" formControlName="email"
                           placeholder="Email" autocomplete="email">
                    <label for="email">Email</label>
                  </div>

                  <button type="submit" class="btn btn-success w-100" [disabled]="forgotPasswordForm.invalid || isLoading">
                    @if (isLoading) {
                      <span class="spinner-border spinner-border-sm me-2"></span>
                    }
                    <span>Wyślij link resetujący</span>
                  </button>

                  <div class="text-center mt-3">
                    <a routerLink="/login" class="btn btn-link">
                      <i class="bi bi-arrow-left me-1"></i>
                      <span>Wróć do logowania</span>
                    </a>
                  </div>
                </form>
              } @else {
                <div class="text-center">
                  <div class="alert alert-success mb-4">
                    <i class="bi bi-check-circle-fill me-2"></i>
                    <span>{{ successMessage }}</span>
                  </div>

                  <a routerLink="/login" class="btn btn-primary">
                    <span>Wróć do logowania</span>
                  </a>
                </div>
              }
            </div>
          </div>

          <!-- Footer -->
          <div class="footer-container mt-3">
            <div class="login-footer text-center">
              <div class="footer-content">
                <span>© 2025 Stragan</span> |
                <a href="mailto:contact@support.straganonline.pl" class="footer-link">Pomoc</a> |
                <a [routerLink]="['/privacy-policy']" class="footer-link">Polityka prywatności</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
