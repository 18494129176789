import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {
  private router = inject(Router);
  private authService = inject(AuthService);

  isLoading = false;
  errorMessage: string | null = null;
  successMessage: string | null = null;

  forgotPasswordForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  });

  onSubmit() {
    if (this.forgotPasswordForm.valid) {
      this.isLoading = true;
      this.errorMessage = null;
      this.successMessage = null;
      
      const { email } = this.forgotPasswordForm.value;
      
      this.authService.requestPasswordReset(email!).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.successMessage = "Link do resetowania hasła został wysłany. Sprawdź swoją skrzynkę email.";
          this.forgotPasswordForm.reset();
        },
        error: (error) => {
          this.isLoading = false;
          
          if (error.error && typeof error.error === 'object') {
            this.errorMessage = error.error.message;
          } else if (typeof error.error === 'string') {
            this.errorMessage = error.error;
          } else {
            this.errorMessage = "Błąd podczas wysyłania prośby o resetowanie hasła";
          }
        }
      });
    }
  }
} 