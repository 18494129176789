import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { Environment } from '../../environments/environment.interface';

declare const FB: any;

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private authService = inject(AuthService);

  isLoading = false;
  errorMessage: string | null = null;
  returnUrl: string = '/';
  isFacebookInitialized = false;
  isSellerRegistration = false;
  showEmailLogin = true;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  ngOnInit() {
    // Check if this is a seller registration
    this.isSellerRegistration = this.route.snapshot.queryParams['seller'] === 'true';
    
    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    
    // If already logged in, redirect to appropriate page
    if (this.authService.isAuthenticated()) {
      this.redirectBasedOnRole();
    }

    // Initialize Facebook SDK
    this.initFacebookSdk();
  }

  private initFacebookSdk() {
    // If SDK is already loaded and initialized, just update the initialization flag
    if (typeof FB !== 'undefined' && FB) {
      console.log('Facebook SDK już załadowany, inicjalizuję...');
      FB.init({
        appId: environment.facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v19.0'
      });
      this.isFacebookInitialized = true;
      return;
    }

    console.log('Ładowanie Facebook SDK...');
    
    // Load the Facebook SDK asynchronously
    (window as any).fbAsyncInit = () => {
      console.log('Facebook SDK załadowany, inicjalizuję...');
      FB.init({
        appId: environment.facebookAppId,
        cookie: true,
        xfbml: true,
        version: 'v19.0'
      });
      this.isFacebookInitialized = true;
    };

    // Remove existing SDK if present
    const existingScript = document.getElementById('facebook-jssdk');
    if (existingScript) {
      existingScript.remove();
    }

    // Load the SDK
    const script = document.createElement('script');
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.id = 'facebook-jssdk';
    script.async = true;
    script.defer = true;
    
    // Add an onload handler to ensure we know when the SDK is loaded
    script.onload = () => {
      console.log('Facebook SDK script loaded.');
      // SDK initialization will happen via fbAsyncInit
    };
    
    script.onerror = () => {
      console.error('Błąd ładowania Facebook SDK');
      // Provide alternative login
      this.showEmailLogin = true;
    };
    
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode?.insertBefore(script, firstScript);
    
    // Set a timeout of 5 seconds to auto-enable the button if FB SDK doesn't load
    setTimeout(() => {
      if (!this.isFacebookInitialized) {
        console.warn('Facebook SDK nie załadował się w ciągu 5 sekund, włączam przycisk awaryjnie.');
        this.isFacebookInitialized = true;
      }
    }, 5000);
  }

  loginWithFacebook() {
    if (!this.isFacebookInitialized) {
      this.errorMessage = "Facebook SDK jeszcze się nie załadował. Spróbuj ponownie za chwilę.";
      return;
    }

    this.isLoading = true;
    this.errorMessage = null;

    FB.login((response: any) => {
      if (response.authResponse) {
        const accessToken = response.authResponse.accessToken;
        this.authService.loginWithFacebook(accessToken, this.isSellerRegistration).subscribe({
          next: () => {
            this.redirectBasedOnRole();
          },
          error: (error) => {
            this.isLoading = false;
            
            // Improved error handling for Facebook login
            if (error.error && typeof error.error === 'object') {
              this.errorMessage = error.error.message;
            } else if (typeof error.error === 'string') {
              this.errorMessage = error.error;
            } else {
              this.errorMessage = $localize`:@@login.error.facebook.failed:Nie udało się zalogować przez Facebook`;
            }
          }
        });
      } else {
        this.isLoading = false;
        this.errorMessage = $localize`:@@login.error.facebook.cancelled:Logowanie przez Facebook zostało anulowane`;
      }
    }, { scope: 'email' });
  }

  onSubmit() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.errorMessage = null;
      
      const { email, password } = this.loginForm.value;
      
      this.authService.login(email!, password!).subscribe({
        next: (response) => {
          this.redirectBasedOnRole();
        },
        error: (error) => {
          this.isLoading = false;
          
          // Improved error handling
          if (error.error && typeof error.error === 'object') {
            this.errorMessage = error.error.message;
          } else if (typeof error.error === 'string') {
            this.errorMessage = error.error;
          } else {
            this.errorMessage = $localize`:@@login.error.invalid:Nieprawidłowy email lub hasło`;
          }
        }
      });
    }
  }

  private redirectBasedOnRole() {
    const user = this.authService.getCurrentUser();
    if (user?.isSeller) {
      this.router.navigate(['/seller/dashboard']);
    } else {
      // Parse the returnUrl to separate path and query params
      const url = new URL(this.returnUrl, window.location.origin);
      const path = url.pathname;
      const queryParams: Record<string, string> = {};
      url.searchParams.forEach((value, key) => {
        queryParams[key] = value;
      });
      
      this.router.navigate([path], { queryParams });
    }
  }

  // Add method to switch modes with page reload
  switchMode(isSeller: boolean) {
    const url = isSeller ? '/login?seller=true' : '/login';
    window.location.href = url;
  }
} 