<div class="login-container">
  <div class="login-overlay">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-5 col-lg-4">
          <div class="login-card compact-width">
            <!-- Logo na górze karty logowania -->
            <div class="login-logo-container text-center">
              <img src="logo_tx_flat.png" alt="Stragan Logo" class="login-logo"
                   onerror="this.onerror=null; this.src='assets/logo_tx_flat.png';">
            </div>

            <!-- Tytuł -->
            <div class="login-header text-center compact">
              <p class="text-muted welcome-text">
                Ustaw nowe hasło
              </p>
            </div>

            <div class="login-body">
              @if (isValidating) {
                <div class="text-center py-4">
                  <span class="spinner-border text-primary"></span>
                  <p class="mt-3 text-muted">Weryfikacja tokenu...</p>
                </div>
              } @else if (!resetComplete) {
                @if (errorMessage) {
                  <div class="alert alert-danger mb-3">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>
                    <span>{{ errorMessage }}</span>
                  </div>

                  <div class="text-center mt-3">
                    <a routerLink="/forgot-password" class="btn btn-link">
                      Wygeneruj nowy link
                    </a>
                    <a routerLink="/login" class="btn btn-primary">
                      Przejdź do logowania
                    </a>
                  </div>
                } @else {
                  <p class="text-muted mb-4">
                    Wprowadź nowe hasło dla swojego konta.
                  </p>

                  <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                    <div class="form-floating mb-3">
                      <input type="password" class="form-control" id="password" formControlName="password"
                             placeholder="Nowe hasło" autocomplete="new-password">
                      <label for="password">Nowe hasło</label>
                    </div>

                    @if (resetPasswordForm.get('password')?.invalid && resetPasswordForm.get('password')?.touched) {
                      <div class="text-danger mb-3 small">
                        @if (resetPasswordForm.get('password')?.errors?.['required']) {
                          <span>Hasło jest wymagane.</span>
                        } @else if (resetPasswordForm.get('password')?.errors?.['minlength']) {
                          <span>Hasło musi mieć co najmniej 8 znaków.</span>
                        }
                      </div>
                    }

                    <div class="form-floating mb-3">
                      <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword"
                             placeholder="Potwierdź hasło" autocomplete="new-password">
                      <label for="confirmPassword">Potwierdź hasło</label>
                    </div>

                    @if (resetPasswordForm.get('confirmPassword')?.touched && resetPasswordForm.errors?.['passwordMismatch']) {
                      <div class="text-danger mb-3 small">
                        Hasła nie są identyczne.
                      </div>
                    }

                    <button type="submit" class="btn btn-success w-100" [disabled]="resetPasswordForm.invalid || isLoading">
                      @if (isLoading) {
                        <span class="spinner-border spinner-border-sm me-2"></span>
                      }
                      <span>Zmień hasło</span>
                    </button>
                  </form>
                }
              } @else {
                <div class="text-center">
                  <div class="alert alert-success mb-4">
                    <i class="bi bi-check-circle-fill me-2"></i>
                    <span>{{ successMessage }}</span>
                  </div>

                  <button (click)="goToLogin()" class="btn btn-primary">
                    <span>Przejdź do logowania</span>
                  </button>
                </div>
              }
            </div>
          </div>

          <!-- Footer -->
          <div class="footer-container mt-3">
            <div class="login-footer text-center">
              <div class="footer-content">
                <span>© 2025 Stragan</span> |
                <a href="mailto:contact@support.straganonline.pl" class="footer-link">Pomoc</a> |
                <a [routerLink]="['/privacy-policy']" class="footer-link">Polityka prywatności</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
