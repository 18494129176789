<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <img src="logo_tx_flat.png" alt="Stragan" height="36" class="d-inline-block align-text-top" 
           onerror="this.onerror=null; this.src='assets/logo_tx_flat.png';">
    </a>
    @if (isAuthenticated) {
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          @if (authService.getCurrentUser()?.isSeller) {
            <li class="nav-item">
              <a class="nav-link" routerLink="/offers" i18n="@@nav.offers">Offers</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/products" i18n="@@nav.products">Products</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/reservations" i18n="@@nav.reservations">Reservations</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/locations" i18n="@@nav.locations">Locations</a>
            </li>
          } @else {
            <li class="nav-item">
              <a class="nav-link" routerLink="/offers/nearby" i18n="@@nav.nearby.offers">Nearby Offers</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/offers/delivery" i18n="@@nav.delivery.offers">Delivery Offers</a>
            </li>
          }
        </ul>
        <div class="d-flex align-items-center">
          @if (authService.getCurrentUser()?.isSeller) {
            <a class="nav-link me-3" routerLink="/seller/about" i18n="@@nav.seller.about">About Me</a>
          } @else {
            <a class="nav-link me-3" routerLink="/user/dashboard" i18n="@@nav.user.dashboard">My Dashboard</a>
            <a class="nav-link me-3" routerLink="/user/about" i18n="@@nav.user.about">About Me</a>
          }
          <button class="btn btn-outline-primary" (click)="authService.logout()" i18n="@@nav.logout">Logout</button>
        </div>
      </div>
    }
  </div>
</nav>

<main class="my-5">
  <div class="container">
    @if (msgSuccess) {
      <p class="alert alert-success mb-4" role="alert">{{ msgSuccess }}</p>
    }
    @if (msgInfo) {
      <p class="alert alert-info mb-4" role="alert">{{ msgInfo }}</p>
    }
    @if (msgError) {
      <p class="alert alert-danger mb-4" role="alert">{{ msgError }}</p>
    }
    <router-outlet />
  </div>
</main>
