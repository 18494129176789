<div class="login-container" [class.customer-mode]="!isSellerRegistration" [class.seller-mode]="isSellerRegistration">
    <div class="login-overlay">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-5 col-lg-4">
                    <div class="login-card compact-width" [class.seller-mode]="isSellerRegistration">
                        <!-- Logo na górze karty logowania -->
                        <div class="login-logo-container text-center">
                            <img src="logo_tx_flat.png" alt="Stragan Logo" class="login-logo"
                            onerror="this.onerror=null; this.src='assets/logo_tx_flat.png';">
                        </div>

                        <!-- Compact welcome text -->
                        <div class="login-header text-center compact">
                            @if (isSellerRegistration) {
                                <p class="text-muted welcome-text" i18n="@@login.welcome.seller">Welcome to Local Food Marketplace</p>
                            } @else {
                                <p class="text-muted welcome-text" i18n="@@login.welcome">Welcome to Local Food Marketplace</p>
                            }
                        </div>

                        <div class="login-body">
                            <!-- Email Login Form - Now at the top -->
                            <div class="email-login show">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div class="form-floating mb-2">
                                        <input type="email" class="form-control" id="email" formControlName="email"
                                            placeholder="Email" autocomplete="email">
                                        <label for="email" i18n="@@login.email.label">Email</label>
                                    </div>
                                    <div class="form-floating mb-2">
                                        <input type="password" class="form-control" id="password" formControlName="password"
                                            placeholder="Hasło" autocomplete="current-password">
                                        <label for="password" i18n="@@login.password.label">Hasło</label>
                                    </div>

                                    <!-- Forgot Password Link -->
                                    <div class="text-end mb-3">
                                        <a routerLink="/forgot-password" class="forgot-password-link">
                                            <small i18n="@@login.forgot.password">Zapomniałem hasła</small>
                                        </a>
                                    </div>

                                    <button type="submit" class="btn btn-success w-100" [disabled]="loginForm.invalid || isLoading">
                                        <span i18n="@@login.button">Zaloguj</span>
                                    </button>
                                </form>
                            </div>

                            <div class="separator text-center my-3">
                                <span>lub</span>
                            </div>

                            <!-- Facebook Login - Now at the bottom -->
                            <button type="button" class="btn btn-facebook w-100"
                                    (click)="loginWithFacebook()" [disabled]="isLoading || !isFacebookInitialized">
                                <i class="bi bi-facebook me-2"></i>
                                <span i18n="@@login.facebook.customer">Kontynuuj z Facebook</span>
                            </button>

                            @if (errorMessage) {
                                <div class="alert alert-danger mt-3 p-2 small">{{ errorMessage }}</div>
                            }

                            <!-- Footer links in single row -->
                            <div class="d-flex justify-content-between align-items-center mt-3 footer-links">
                                <button type="button" class="btn btn-sm btn-link p-0" (click)="switchMode(!isSellerRegistration)">
                                    <small>
                                        @if (!isSellerRegistration) {
                                            <i class="bi bi-shop me-1"></i>
                                            <span i18n="@@login.seller.option">Jestem sprzedawcą</span>
                                        } @else {
                                            <i class="bi bi-person me-1"></i>
                                            <span i18n="@@login.customer.option">Jestem klientem</span>
                                        }
                                    </small>
                                </button>

                                <small>
                                    <span i18n="@@login.no.account">Nie masz konta?</span>
                                    <a routerLink="/register" [queryParams]="{seller: isSellerRegistration}" i18n="@@login.register">Zarejestruj się</a>
                                </small>
                            </div>
                        </div>
                    </div>

                    <!-- Improved Footer -->
                    <div class="footer-container mt-3">
                        <div class="login-footer text-center">
                            <div class="footer-content">
                                <span i18n="@@footer.copyright">© 2025 Stragan</span> |
                                <a href="mailto:contact@support.straganonline.pl" class="footer-link" i18n="@@footer.contact">Pomoc</a> |
                                <a [routerLink]="['/privacy-policy']" class="footer-link" i18n="@@footer.privacy">Polityka prywatności</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
