import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login.component';
import { authGuard } from './auth/auth.guard';
import { userEditGuard } from './auth/user-edit.guard';
import { locationEditGuard } from './auth/location-edit.guard';
import { ForgotPasswordComponent } from './auth/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'register', loadComponent: () => import('./auth/register.component').then(m => m.RegisterComponent) },
  { path: 'verify-email', loadComponent: () => import('./auth/verify-email.component').then(m => m.VerifyEmailComponent) },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'privacy-policy', loadComponent: () => import('./common/privacy-policy.component').then(m => m.PrivacyPolicyComponent) },
  { path: 'offers/nearby', loadComponent: () => import('./offer/nearby-offers.component').then(m => m.NearbyOffersComponent) },
  { path: 'offers/delivery', loadComponent: () => import('./offer/delivery-offers.component').then(m => m.DeliveryOffersComponent) },
  { path: 'offers/add', loadComponent: () => import('./offer/offer-edit.component').then(m => m.OfferEditComponent) },
  { path: 'offers/:id', loadComponent: () => import('./offer/offer-details.component').then(m => m.OfferDetailsComponent) },
  {
    path: '',
    canActivate: [authGuard],
    children: [
      { path: '', loadComponent: () => import('./home/home.component').then(m => m.HomeComponent) },
      { path: 'home', loadComponent: () => import('./home/home.component').then(m => m.HomeComponent) },
      { path: 'seller/dashboard', loadComponent: () => import('./seller/seller-dashboard.component').then(m => m.SellerDashboardComponent) },
      { path: 'seller/about', loadComponent: () => import('./seller/seller-about.component').then(m => m.SellerAboutComponent) },
      { path: 'user/dashboard', loadComponent: () => import('./user/user-dashboard.component').then(m => m.UserDashboardComponent) },
      { path: 'user/about', loadComponent: () => import('./user/user-about.component').then(m => m.UserAboutComponent) },
      { path: 'offers', loadComponent: () => import('./offer/offer-list.component').then(m => m.OfferListComponent) },
      { path: 'offers/edit/:id', loadComponent: () => import('./offer/offer-edit.component').then(m => m.OfferEditComponent) },
      { path: 'users', redirectTo: 'user/about' },
      { path: 'users/add', loadComponent: () => import('./user/user-add.component').then(m => m.UserAddComponent) },
      {
        path: 'users/edit/:id',
        loadComponent: () => import('./user/user-edit.component').then(m => m.UserEditComponent),
        canActivate: [userEditGuard]
      },
      { path: 'sellers', loadComponent: () => import('./seller/seller-list.component').then(m => m.SellerListComponent) },
      { path: 'sellers/add', loadComponent: () => import('./seller/seller-add.component').then(m => m.SellerAddComponent) },
      { path: 'sellers/edit/:id', loadComponent: () => import('./seller/seller-edit.component').then(m => m.SellerEditComponent) },
      { path: 'products', loadComponent: () => import('./product/product-list.component').then(m => m.ProductListComponent) },
      { path: 'products/add', loadComponent: () => import('./product/product-edit.component').then(m => m.ProductEditComponent) },
      { path: 'products/edit/:id', loadComponent: () => import('./product/product-edit.component').then(m => m.ProductEditComponent) },
      { path: 'variants', loadComponent: () => import('./variant/variant-list.component').then(m => m.VariantListComponent) },
      { path: 'variants/add', loadComponent: () => import('./variant/variant-edit.component').then(m => m.VariantEditComponent) },
      { path: 'variants/edit/:id', loadComponent: () => import('./variant/variant-edit.component').then(m => m.VariantEditComponent) },
      { path: 'offerItems', loadComponent: () => import('./offer-item/offer-item-list.component').then(m => m.OfferItemListComponent) },
      { path: 'offerItems/add', loadComponent: () => import('./offer-item/offer-item-edit.component').then(m => m.OfferItemEditComponent) },
      { path: 'offerItems/edit/:id', loadComponent: () => import('./offer-item/offer-item-edit.component').then(m => m.OfferItemEditComponent) },
      { path: 'locations', loadComponent: () => import('./location/location-list.component').then(m => m.LocationListComponent) },
      { path: 'locations/add', loadComponent: () => import('./location/location-edit.component').then(m => m.LocationEditComponent) },
      {
        path: 'locations/edit/:id',
        loadComponent: () => import('./location/location-edit.component').then(m => m.LocationEditComponent),
        canActivate: [locationEditGuard]
      },
      { path: 'reservations', loadComponent: () => import('./reservation/reservation-list.component').then(m => m.ReservationListComponent) },
      { path: 'reservations/add', loadComponent: () => import('./reservation/reservation-edit.component').then(m => m.ReservationEditComponent) },
      { path: 'reservations/user/add', loadComponent: () => import('./reservation/user-reservation.component').then(m => m.UserReservationComponent) },
      { path: 'reservations/edit/:id', loadComponent: () => import('./reservation/reservation-edit.component').then(m => m.ReservationEditComponent) },
      { path: 'addresses', loadComponent: () => import('./address/address-list.component').then(m => m.AddressListComponent) },
      { path: 'addresses/edit', loadComponent: () => import('./address/address-edit.component').then(m => m.AddressEditComponent) },
      { path: 'addresses/edit/:id', loadComponent: () => import('./address/address-edit.component').then(m => m.AddressEditComponent) },
      { path: 'items', loadComponent: () => import('./item/item-list.component').then(m => m.ItemListComponent) },
      { path: 'items/add', loadComponent: () => import('./item/item-add.component').then(m => m.ItemAddComponent) },
      { path: 'items/edit/:id', loadComponent: () => import('./item/item-edit.component').then(m => m.ItemEditComponent) },
      { path: 'inventories', loadComponent: () => import('./inventory/inventory-list.component').then(m => m.InventoryListComponent) },
      { path: 'inventories/add', loadComponent: () => import('./inventory/inventory-add.component').then(m => m.InventoryAddComponent) },
      { path: 'inventories/edit/:id', loadComponent: () => import('./inventory/inventory-edit.component').then(m => m.InventoryEditComponent) },
      { path: 'schedules', loadComponent: () => import('./schedule/schedule-list.component').then(m => m.ScheduleListComponent) },
      { path: 'schedules/add', loadComponent: () => import('./schedule/schedule-edit.component').then(m => m.ScheduleEditComponent) },
      { path: 'schedules/edit/:id', loadComponent: () => import('./schedule/schedule-edit.component').then(m => m.ScheduleEditComponent) },
      { path: 'error', loadComponent: () => import('./error/error.component').then(m => m.ErrorComponent) }
    ]
  },
  { path: '**', redirectTo: '/login' }
];
