import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { AuthService } from './auth.service';

// Custom validator dla zgodności haseł
function passwordMatchValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  if (password && confirmPassword && password.value !== confirmPassword.value) {
    return { passwordMismatch: true };
  }
  return null;
}

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private authService = inject(AuthService);

  resetToken: string | null = null;
  isLoading = false;
  isValidating = true;
  errorMessage: string | null = null;
  successMessage: string | null = null;
  resetComplete = false;

  resetPasswordForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]),
    confirmPassword: new FormControl('', [
      Validators.required
    ])
  }, { validators: passwordMatchValidator });

  ngOnInit() {
    // Pobierz token z parametrów zapytania
    this.resetToken = this.route.snapshot.queryParams['token'];
    
    if (!this.resetToken) {
      this.errorMessage = "Brak tokenu resetowania hasła. Sprawdź poprawność linku.";
      this.isValidating = false;
      return;
    }

    // Zweryfikuj token resetowania hasła
    this.validateToken();
  }

  private validateToken() {
    this.isValidating = true;
    this.errorMessage = null;

    this.authService.validateResetToken(this.resetToken!).subscribe({
      next: (response) => {
        this.isValidating = false;
      },
      error: (error) => {
        this.isValidating = false;
        if (error.error && typeof error.error === 'object') {
          this.errorMessage = error.error.message;
        } else if (typeof error.error === 'string') {
          this.errorMessage = error.error;
        } else {
          this.errorMessage = "Nieprawidłowy lub wygasły token resetowania hasła.";
        }
      }
    });
  }

  onSubmit() {
    if (this.resetPasswordForm.valid && this.resetToken) {
      this.isLoading = true;
      this.errorMessage = null;
      
      const password = this.resetPasswordForm.get('password')?.value;
      
      this.authService.resetPassword(this.resetToken, password!).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.resetComplete = true;
          this.successMessage = "Hasło zostało pomyślnie zmienione. Możesz teraz się zalogować.";
        },
        error: (error) => {
          this.isLoading = false;
          
          if (error.error && typeof error.error === 'object') {
            this.errorMessage = error.error.message;
          } else if (typeof error.error === 'string') {
            this.errorMessage = error.error;
          } else {
            this.errorMessage = "Błąd podczas resetowania hasła. Spróbuj ponownie później.";
          }
        }
      });
    }
  }

  goToLogin() {
    this.router.navigate(['/login']);
  }
} 