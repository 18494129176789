import { inject } from '@angular/core';
import { Router, type CanActivateFn } from '@angular/router';
import { AuthService } from './auth.service';
import { LocationService } from '../location/location.service';
import { map, catchError, of } from 'rxjs';

/**
 * Guard do ochrony edycji lokalizacji.
 * Sprawdza, czy lokalizacja należy do aktualnie zalogowanego użytkownika.
 * Zapobiega to możliwości edycji lokalizacji innych użytkowników przez podmianę ID w URL.
 */
export const locationEditGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const locationService = inject(LocationService);
  const router = inject(Router);
  
  // Sprawdź, czy użytkownik jest zalogowany
  if (!authService.isAuthenticated()) {
    router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
  
  // Pobierz ID lokalizacji z parametrów URL
  const requestedLocationId = +route.params['id'];
  
  // Jeśli nie ma ID (np. dodajemy nową lokalizację), pozwól na dostęp
  if (!requestedLocationId) {
    return true;
  }
  
  // Pobierz szczegóły lokalizacji i sprawdź, czy należy do zalogowanego użytkownika
  return locationService.getLocation(requestedLocationId).pipe(
    map(location => {
      // Pomyślnie pobrano lokalizację - backend zwraca tylko lokalizacje należące do użytkownika,
      // więc jeśli udało się pobrać, to znaczy że użytkownik ma prawo do edycji
      return true;
    }),
    catchError(error => {
      // Błąd dostępu - przekieruj do listy lokalizacji
      router.navigate(['/locations']);
      return of(false);
    })
  );
}; 