import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet, RouterLink } from '@angular/router';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterLink],
  templateUrl: './app.component.html'
})
export class AppComponent {
  authService = inject(AuthService);
  isAuthenticated = this.authService.isAuthenticated();

  constructor() {
    this.authService.currentUser$.subscribe(() => {
      this.isAuthenticated = this.authService.isAuthenticated();
    });
  }

  get msgSuccess() {
    return history.state?.msgSuccess;
  }

  get msgInfo() {
    return history.state?.msgInfo;
  }

  get msgError() {
    return history.state?.msgError;
  }
}
