import { Injectable, inject } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private authService = inject(AuthService);
  private router = inject(Router);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Logowanie błędu do konsoli
        console.error('HTTP Error:', error);

        // Specjalna obsługa błędów 401 Unauthorized
        if (error.status === 401) {
          // Dla rejestracjii i logowania nie wylogowujemy, przekazujemy błąd dalej
          if (request.url.includes('/api/auth/register') || 
              request.url.includes('/api/auth/login') || 
              request.url.includes('/api/auth/facebook')) {
            // Przekształć błąd, aby był łatwiejszy do obsługi
            let errorMessage = 'Wystąpił błąd podczas autoryzacji.';
            
            // Spróbuj wyciągnąć wiadomość z różnych miejsc w odpowiedzi
            if (error.error && typeof error.error === 'object' && error.error.message) {
              errorMessage = error.error.message;
            } else if (typeof error.error === 'string') {
              try {
                const parsedError = JSON.parse(error.error);
                errorMessage = parsedError.message || errorMessage;
              } catch (e) {
                errorMessage = error.error;
              }
            }
            
            // Utwórz nowy obiekt błędu z odpowiednimi informacjami
            const enhancedError = {
              status: error.status,
              statusText: error.statusText,
              message: errorMessage,
              error: {
                message: errorMessage
              }
            };
            
            return throwError(() => enhancedError);
          }
          
          // Dla innych zapytań, jeśli błąd 401, wyloguj użytkownika
          this.authService.logout();
          this.router.navigate(['/login']);
        }
        
        return throwError(() => error);
      })
    );
  }
} 